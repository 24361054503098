<template>
  <div>
    <!-- Table Top -->

    <!-- Search -->
    <div class="d-flex tw-mb-[15px] tw-gap-3 align-items-center">
      <search-input
        :onInput="onSearch"
        :placeholder="'Cari berdasarkan ID atau Nama Paket...'"
      />
      <b-button variant="primary" @click="fetchData">
        <feather-icon icon="RefreshCcwIcon" class="mr-50" />
        <span class="text-nowrap">Refresh</span>
      </b-button>

      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        v-b-modal.tambah-kategori-Tryout
        variant="primary"
        @click="form.show = false"
      >
        <feather-icon icon="PlusCircleIcon" class="mr-50" />
        <span class="text-nowrap">Tambah</span>
      </b-button>
    </div>

    <div class="tw-inline-flex tw-mb-5">
      <custom-filter
        :data="filterList"
        :filterValue="filterValue"
        :initialValue="initialValue"
        :handleChange="handleChange"
      />
    </div>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class=""></div>

      <!-- modal tambah kategori Tryout-->
      <b-modal
        id="tambah-kategori-Tryout"
        cancel-variant="outline-secondary"
        ok-title="Tambah"
        cancel-title="Cancel"
        centered
        no-close-on-backdrop
        size="lg"
        title="Tambah Paket Kategori"
        @show="resetModal"
        @hidden="resetModal"
        @ok="(e) => handleOk(e, 'store')"
      >
        <b-form>
          <b-overlay :show="isSubmitting" rounded="sm">
            <b-card-actions title="Informasi Paket" action-collapse>
              <b-form-group>
                <label for="nama">Nama</label>
                <b-form-input
                  for="nama"
                  v-model.trim="form.nama"
                  type="text"
                  placeholder="Nama paket tryout"
                />
              </b-form-group>

              <b-row>
                <b-col>
                  <b-form-group>
                    <label for="kategori">Kategori</label>
                    <v-select
                      id="kategori"
                      v-model="form.kategori_soal_id"
                      :reduce="kategori => kategori.id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="listKategoriTryout"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <!-- <b-row>
                <b-col sm="12" md="6">
                  <b-form-group>
                    <label for="total_durasi">Total Durasi</label>
                    <b-form-input
                      id="total_durasi"
                      v-model.number="form.total_durasi"
                      type="number"
                      placeholder="Total Durasi"
                      min="0"
                      step="0.5"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>

                <b-col sm="12" md="6">
                  <b-form-group>
                    <label for="total_soal">Total Soal</label>
                    <b-form-input
                      id="total_soal"
                      v-model.number="form.total_soal"
                      type="number"
                      placeholder="Total Soal"
                      min="0"
                      step="1"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
              </b-row>-->
            </b-card-actions>

            <b-card-actions title="List Paket Mapel" action-collapse>
              <div class="mb-1 d-flex align-items-center justify-content-end">
                <b-button variant="outline-primary" @click="tambahPaketMapel">
                  <feather-icon icon="PlusCircleIcon" class="mr-50" />
                  <span class="text-nowrap">Tambah Paket Mapel</span>
                </b-button>
              </div>

              <draggable
                tag="ul"
                :list="form.paket_mapels"
                class="list-group"
                handle=".handle"
              >
                <li
                  class="list-group-item d-flex align-items-center"
                  v-for="(element, idx) in form.paket_mapels"
                  :key="idx"
                >
                  <feather-icon icon="MenuIcon" class="mr-1 handle button" />

                  <div class="w-100">
                    <b-row class="w-100">
                      <b-col>
                        <b-form-group
                          label="Paket Mapel"
                          label-for="paket-mapel"
                        >
                          <v-select
                            id="paket-mapel"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="title"
                            :options="listPaketMapel"
                            v-model="element.paket_mapel"
                            @option:selected="setSelectedPaketMapel(idx)"
                          >
                            <template #option="{ title, jumlah }">
                              <div class="pr-2 d-flex justify-content-between">
                                <span>{{ title }}</span>
                                <span>{{ jumlah }}</span>
                              </div>
                            </template>
                          </v-select>
                        </b-form-group>
                      </b-col>
                      <b-col cols="3">
                        <b-form-group label="Durasi" label-for="durasi">
                          <b-form-input
                            id="durasi"
                            type="number"
                            min="0"
                            step="0.1"
                            v-model.number="element.durasi"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row class="w-100">
                      <b-col>
                        <b-form-group
                          label="Kategori/ Mapel"
                          label-for="kategori-mapel"
                        >
                          <b-form-input
                            id="kategori-mapel"
                            disabled
                            v-model="element.kategori_mapel"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="3">
                        <b-form-group
                          label="Jumlah Soal"
                          label-for="jumlah-soal"
                        >
                          <b-form-input
                            id="jumlah-soal"
                            disabled
                            v-model="element.jumlah"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>

                  <!-- <input type="text" class="form-control" v-model="element.text" /> -->
                  <feather-icon
                    icon="XIcon"
                    class="ml-1"
                    @click="removePaketMapel(idx)"
                  />
                </li>
              </draggable>
            </b-card-actions>
          </b-overlay>
        </b-form>
      </b-modal>

      <!-- modal edit kategori Tryout-->
      <!-- <b-modal
        id="edit-kategori-Tryout"
        cancel-variant="outline-secondary"
        ok-title="Edit"
        cancel-title="Close"
        centered
        title="Edit Kategori Tryout"
        @ok="updatekategoryTryout"
      >
        <b-form>
          <b-card-actions title="Informasi Paket" action-collapse>
            <b-form-group>
              <label for="kode-kategori">Kode Kategori</label>
              <b-form-input
                v-model="form.kode"
                id="kode-kategori"
                type="text"
                placeholder="Nama singkatan kategori"
              />
            </b-form-group>
            <b-form-group>
              <label for="show">Nama Kategori</label>
              <b-form-input
                v-model="form.nama"
                id="show"
                type="text"
                placeholder="Kategori Tryout"
              />
            </b-form-group>
          </b-card-actions>

          <b-card-actions title="List Paket Mapel" action-collapse></b-card-actions>
        </b-form>
      </b-modal>-->

      <!-- modal hapus kategori Tryout-->
      <b-modal
        id="hapus-paket-tryout"
        cancel-variant="outline-secondary"
        ok-title="Hapus"
        cancel-title="Close"
        centered
        title="Hapus"
        @show="resetModal"
        @hidden="resetModal"
        @ok="deletePaketKategori(listPaketKategori[currentPaketIndex].id)"
      >
        <b-form>
          <b-overlay :show="isSubmitting" rounded="sm">
            <b-form-group>
              <p>Apakah anda yakin ingin menghapus?</p>
            </b-form-group>
          </b-overlay>
        </b-form>
      </b-modal>

      <!-- modal lihat paket Tryout-->
      <b-modal
        id="lihat-kategori-Tryout"
        cancel-variant="outline-secondary"
        cancel-title="Cancel"
        centered
        size="lg"
        title="Lihat Kategori"
      >
        <b-form>
          <ul class="list-group" v-if="form.show">
            <li
              class="list-group-item d-flex align-items-center"
              v-for="(element, idx) in form.paket_mapels"
              :key="idx"
            >
              <div class="w-100">
                <b-row class="w-100">
                  <b-col>
                    <b-form-group label="Paket Mapel" label-for="paket-mapel">
                      <b-form-input
                        id="paket-mapel"
                        type="text"
                        v-model.number="element.nama"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="2">
                    <b-form-group label="Durasi" label-for="durasi">
                      <b-form-input
                        id="durasi"
                        type="number"
                        v-model.number="element.pivot.durasi"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="1" class="col align-self-center">
                    <b-button
                      variant="outline-primary"
                      class="btn-icon rounded-circle"
                      :to="{ name: 'manajemen-paket-mapel-detail', params: { id: element.id } }"
                    >
                      <feather-icon icon="EyeIcon" />
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </li>
          </ul>
        </b-form>
      </b-modal>

      <b-table
        :busy="isLoading"
        class="position-relative"
        :items="listPaketKategori"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        striped
        hover
        empty-text="Tidak ada data ditemukan"
      >
        <template #table-busy>
          <div class="my-2 text-center text-danger">
            <b-spinner class="mr-1 align-middle"></b-spinner>
            <strong>Sedang memuat data...</strong>
          </div>
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              @click="show(data.item.id)"
              v-b-modal.lihat-kategori-Tryout
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">Lihat Paket</span>
            </b-dropdown-item>

            <!-- <b-dropdown-item @click="setRowIndex(data.item)" v-b-modal.edit-kategori-Tryout>
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>-->

            <b-dropdown-item
              @click="setRowIndex(data.item)"
              v-b-modal.hapus-paket-tryout
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <!-- Footer -->
      <div class="mx-2 mb-2">
        <pagination
          :from="from"
          :to="to"
          :pageTotal="pageTotal"
          :total="total"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BModal,
  VBModal,
  BForm,
  BFormGroup,
  BCardText,
  BButton,
  BTable,
  BListGroupItem,
  BDropdown,
  BDropdownItem,
  BSpinner,
  BFormCheckbox,
  BOverlay,
} from "bootstrap-vue";
// import store from "@/store";
import { ref, onMounted, watch } from "@vue/composition-api";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";

import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import draggable from "vuedraggable";
import vSelect from "vue-select";
import { useRouter } from "@core/utils/utils";
import debounce from "lodash.debounce"

import repository from "@repofactory";
import SearchInput from '@/@core/components/base/SearchInput.vue';
import CustomFilter from '@/@core/components/base/CustomFilter.vue';
import Pagination from '@/@core/components/base/Pagination.vue';
const repoKategori = repository.get("kategoriSoalRepository");
const repoPaketMapel = repository.get("paketMapelRepository");
const repoPaketKategori = repository.get("paketKategoriRepository");

export default {
  components: {
    // ModalTambah,
    BSpinner,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BModal,
    BFormCheckbox,
    VBModal,
    BForm,
    BFormGroup,
    BCardText,
    BDropdown,
    flatPickr,
    BDropdownItem,
    ToastificationContent,
    BCardActions,
    draggable,
    BListGroupItem,
    vSelect,
    BOverlay,
    SearchInput,
    CustomFilter,
    Pagination,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  setup() {
    const toast = useToast();
    const { route } = useRouter();

    const isLoading = ref(true);
    const isSubmitting = ref(false);
    const isError = ref(false);
    const kategoryTryout = ref([]);
    const listPaketKategori = ref([]);
    const currentPaketIndex = ref(null);
    const form = ref({
      show: false,
      id: null,
      nama: "",
      kategori_soal_id: null,
      total_durasi: 0,
      total_soal: 0,
      paket_mapels: [],
    });
    const keyword = ref("")

    const listPaketMapel = ref([]);
    const listKategoriTryout = ref([]);

    const from = ref(0);
    const to = ref(0);
    const total = ref(0);
    const pageTotal = ref(0);

    const filterList = ref([
      {
        name: "Kategori",
        list: []
      }
    ])

    const initialValue = ref({
      "Kategori": null
    })

    const filterValue = ref({...initialValue})

    const tableColumns = [
      {
        key: "id",
        label: "ID",
        sortable: true,
      },
      {
        key: "nama",
        label: "Nama Paket",
        formatter: (value, key, item) => {
          return value.toUpperCase();
        },
        sortable: true,
      },
      {
        key: "kategori_soal_id",
        label: "Kategori",
        sortable: true,
        formatter: (value, key, item) => {
          return item.kategori_soal.nama.toUpperCase();
        },
      },
      {
        key: "total_durasi",
        label: "Total Durasi",
        sortable: true,
      },
      {
        key: "total_soal",
        label: "Total Soal",
        sortable: true,
      },
      {
        key: "actions",
      },
    ];

    onMounted(async () => {
      fetchData();
    });

    watch([() => route.value.query.page], () => fetchData(route.value.query.page, keyword.value, filterValue.value["Kategori"]))

    watch([keyword, () => filterValue.value], debounce(() => fetchData(1, keyword.value, filterValue.value["Kategori"]), 500))

    const handleOk = (e, method) => {
      e.preventDefault();
      switch (method) {
        case "store":
          submitPaketKategori();
          break;

        case "destroy":
          deletePaketKategori(
            listPaketKategori.value[currentPaketIndex.value].id
          );
          break;

        default:
          showToast(
            "Error",
            `Method ${method} tidak tersedia.`,
            "warning",
            "AlertTriangleIcon"
          );
          break;
      }
    };

    const setRowIndex = (item) => {
      currentPaketIndex.value = listPaketKategori.value.findIndex(
        (paket) => paket.id == item.id
      );
    };

    const showToast = (title, text, variant, icon = "BellIcon") => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    };

    const resetModal = () => {
      form.value = {
        show: false,
        id: null,
        nama: "",
        kategori_soal_id: null,
        total_durasi: 0,
        total_soal: 0,
        paket_mapels: [],
      };
    };

    const tambahPaketMapel = () => {
      form.value.paket_mapels.push({
        paket_mapel: null,
        durasi: 0,
        kategori_mapel: "",
        jumlah: 0,
      });
    };

    const removePaketMapel = (idx) => {
      const current = form.value.paket_mapels;
      current.splice(idx, 1);
    };

    const setSelectedPaketMapel = (idx) => {
      const current = form.value.paket_mapels[idx];
      const paket = current.paket_mapel;

      current.kategori_mapel = paket.kategori_mapel;
      current.jumlah = paket.jumlah;
      current.id = paket.id;

      // form.value.total_soal += parseInt(paket.jumlah)
    };

    const fetchData = async (page = 1, keyword, filter) => {
      isLoading.value = true;
      fetchPaketMapel();
      fetchKategoriTryout();

      await repoPaketKategori
        .get(page, keyword, filter, 10)
        .then(function (response) {
          isLoading.value = false;

          listPaketKategori.value = response.data.data.data;

          from.value = response.data.data.from
          to.value = response.data.data.to
          pageTotal.value = response.data.data.last_page

          total.value = response.data.data.total
        })
        .catch(function (error) {
          if (error.response) {
            showToast(
              "Error",
              error.response.data.message,
              "danger",
              "AlertTriangleIcon"
            );
          } else if (error.request) {
            showToast(
              "Error",
              "Tidak bisa request data ke server",
              "danger",
              "AlertTriangleIcon"
            );
          } else {
            showToast("Error", error.message, "danger", "AlertTriangleIcon");
          }
          isLoading.value = false;
          isError.value = true;
        });
    };

    const show = async (id) => {
      await repoPaketKategori
        .show(id)
        .then(function (response) {
          form.value.show = true;
          form.value.paket_mapels = response.data.data.paket_mapels;
        })
        .catch(function (error) {
          if (error.response) {
            showToast(
              "Error",
              error.response.data.message,
              "danger",
              "AlertTriangleIcon"
            );
          } else if (error.request) {
            showToast(
              "Error",
              "Tidak bisa request data ke server",
              "danger",
              "AlertTriangleIcon"
            );
          } else {
            showToast("Error", error.message, "danger", "AlertTriangleIcon");
          }
          isError.value = true;
        });
    };

    const fetchPaketMapel = async () => {
      await repoPaketMapel
        .get()
        .then(function (response) {
          listPaketMapel.value = response.data.data.data.map((val) => {
            return {
              id: val.id,
              title: val.nama.toUpperCase(),
              jumlah: val.soals_count,
              kategori_mapel:
                val.mapel_soal.kategori_soal.kode.toUpperCase() +
                "/" +
                val.mapel_soal.nama.toUpperCase(),
            };
          });
        })
        .catch(function (error) {
          if (error.response) {
            showToast(
              "Error",
              error.response.data.message,
              "danger",
              "AlertTriangleIcon"
            );
          } else if (error.request) {
            showToast(
              "Error",
              "Tidak bisa request data ke server",
              "danger",
              "AlertTriangleIcon"
            );
          } else {
            showToast("Error", error.message, "danger", "AlertTriangleIcon");
          }
        });
    };

    const fetchKategoriTryout = async () => {
      await repoKategori
        .get()
        .then(function (response) {
          listKategoriTryout.value = response.data.data.map((val) => {

            return {
              id: val.id,
              title: val.nama.toUpperCase(),
              kode: val.kode.toUpperCase(),
            };
          });

          filterList.value[0].list = response.data.data.map(item => ({
            label: item.nama,
            value: item.kode
          }))
        })
        .catch(function (error) {
          if (error.response) {
            showToast(
              "Error",
              error.response.data.message,
              "danger",
              "AlertTriangleIcon"
            );
          } else if (error.request) {
            showToast(
              "Error",
              "Tidak bisa request data ke server",
              "danger",
              "AlertTriangleIcon"
            );
          } else {
            showToast("Error", error.message, "danger", "AlertTriangleIcon");
          }
        });
    };

    const submitPaketKategori = async () => {
      isSubmitting.value = true;
      const data = {
        nama: form.value.nama,
        kategori_soal_id: form.value.kategori_soal_id,
        paket_mapels: form.value.paket_mapels,
        total_durasi: form.value.paket_mapels.reduce(
          (n, { durasi }) => n + durasi,
          0
        ),
        total_soal: form.value.paket_mapels.reduce(
          (n, { jumlah }) => n + jumlah,
          0
        ),
      };

      await repoPaketKategori
        .create(data)
        .then((response) => {
          listPaketKategori.value.push(response.data.data);

          showToast("Notifikasi", response.data.message, "primary");

          isSubmitting.value = false;
          resetModal();
        })
        .catch((error) => {
          if (error.response) {
            showToast(
              "Error",
              error.response.data.message,
              "danger",
              "AlertTriangleIcon"
            );
          } else if (error.request) {
            showToast(
              "Error",
              "Tidak bisa request data ke server",
              "danger",
              "AlertTriangleIcon"
            );
          } else {
            showToast("Error", error.message, "danger", "AlertTriangleIcon");
          }
          isSubmitting.value = false;
        });
    };

    const updatekategoryTryout = async () => {
      isSubmitting.value = true;

      await repoKategori
        .update(
          {
            kode: form.value.kode,
            nama: form.value.nama,
          },
          form.value.id
        )
        .then((response) => {
          const currentKategori = kategoryTryout.value[currentPaketIndex.value];
          currentKategori.kode = response.data.data.kode;
          currentKategori.nama = response.data.data.nama;

          showToast("Notifikasi", response.data.message, "primary");

          isSubmitting.value = false;
          resetModal();
        })
        .catch((error) => {
          if (error.response) {
            showToast(
              "Error",
              error.response.data.message,
              "danger",
              "AlertTriangleIcon"
            );
          } else if (error.request) {
            showToast(
              "Error",
              "Tidak bisa request data ke server",
              "danger",
              "AlertTriangleIcon"
            );
          } else {
            showToast("Error", error.message, "danger", "AlertTriangleIcon");
          }
          isSubmitting.value = false;
          resetModal();
        });
    };

    const deletePaketKategori = async (id) => {
      isSubmitting.value = true;

      await repoPaketKategori
        .delete(id)
        .then((response) => {
          listPaketKategori.value.splice(currentPaketIndex.value, 1);
          showToast("Notifikasi", response.data.message, "primary");
          resetModal();

          isSubmitting.value = false;
        })
        .catch((error) => {
          if (error.response) {
            showToast(
              "Error",
              error.response.data.message,
              "danger",
              "AlertTriangleIcon"
            );
          } else if (error.request) {
            showToast(
              "Error",
              "Tidak bisa request data ke server",
              "danger",
              "AlertTriangleIcon"
            );
          } else {
            showToast("Error", error.message, "danger", "AlertTriangleIcon");
          }
          isSubmitting.value = false;
          resetModal();
        });
    };

    fetchData();

    const onSearch = (e) => {
      keyword.value = e.currentTarget.value
    }

    const handleChange = (value) => {
      filterValue.value = {...value}
    };

    return {
      isError,
      isLoading,
      isSubmitting,
      kategoryTryout,
      tableColumns,
      form,
      listPaketMapel,
      listKategoriTryout,
      listPaketKategori,
      currentPaketIndex,
      filterList,
      filterValue,
      initialValue,
      from,
      to,
      total,
      pageTotal,
      keyword,

      // methods
      setRowIndex,
      fetchData,
      resetModal,
      submitPaketKategori,
      updatekategoryTryout,
      deletePaketKategori,
      tambahPaketMapel,
      setSelectedPaketMapel,
      removePaketMapel,
      handleOk,
      show,
      onSearch,
      handleChange
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
